window.$ = window.jQuery = require('jquery');
const bootstrap = require('bootstrap');

// Non-React Popovers.
[].slice
  .call(document.querySelectorAll('[data-bs-toggle="popover"]'))
  .map(function (popoverTriggerEl) {
    return new bootstrap.Popover(popoverTriggerEl);
  });

// Always return true from confirm dialogs if the user prevents them from showing
window.nativeConfirm = window.confirm;
window.confirm = (message) => {
  const timeBefore = new Date();
  let confirmBool = window.nativeConfirm(message);
  const timeAfter = new Date();
  if (timeAfter - timeBefore < 350) {
    confirmBool = true;
  }
  return confirmBool;
};

/**
 * Current CSRF Token
 *
 * @returns {string | null}
 */
window.currentCsrfToken = () =>
  document.querySelector("meta[name='csrf-token']").getAttribute('content');

// Auto-hide notifications
setTimeout(function () {
  $('.notification-autohide').alert('close');
}, 9000);

//Fixes issue with old cached sort key when upgrading from 3047
//This is a temporary fix until all customers have upgraded to 3048 or higher.
if (
  localStorage.getItem('webcp_myQuotesSortByColumn') === '"QuoteNumberSortKey"'
) {
  localStorage.removeItem('webcp_myQuotesSortByColumn');
}
if (
  localStorage.getItem('webcp_myOrdersSortByColumn') === '"QuoteNumberSortKey"'
) {
  localStorage.removeItem('webcp_myOrdersSortByColumn');
}
if (
  localStorage.getItem('webcp_myPlansSortByColumn') === '"QuoteNumberSortKey"'
) {
  localStorage.removeItem('webcp_myPlansSortByColumn');
}
